<template>
  <v-container fill-height>
    <v-row align-content="center">
      <v-col cols="12" sm="12" md="12" lg="12" align-self="center">
        <v-card flat class="mx-auto my-12" max-width="450" :loading="loading">
          <v-img :src="require('../assets/' + img_source)"></v-img>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                outlined
                autocomplete="off"
                v-model="username"
                :rules="[(v) => !!v || 'UserName obligatoire']"
                label="Utilisateur"
                prepend-icon="mdi-account-circle"
              ></v-text-field>
              <v-text-field
                outlined
                @click:append="showPassword = !showPassword"
                autocomplete="off"
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :rules="[(v) => !!v || 'Mot de passe obligatoire']"
                label="Mot de passe"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-icon="mdi-lock"
                @keydown.enter="submit"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="submit" :disabled="loading" text>
              <v-icon color="green" v-if="isEndpointAvailable"
                >mdi-network</v-icon
              >
              <v-icon color="red" v-else>mdi-network-off</v-icon>
              Confirmer
            </v-btn>
            <v-btn @click="clear" :disabled="loading" text> Effacer </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          top
          color="red lighten-2"
        >
          {{ snackbar_text }}
        </v-snackbar>
      </v-col>
      <pswform
        :me="$store.state.me"
        :showform="isPswOpen"
        @close="closePswForm"
        @pwd_changed="pwd_changed"
        :key="kpsw"
      >
      </pswform>
    </v-row>
  </v-container>
</template>

<script>
import { onLogin, onLogout } from "../vue-apollo.js";
import { code } from "print/data.js";
import LOGIN from "../graphql/LOGIN.gql";
import LOGOUT from "../graphql/LOGOUT.gql";
import OPTIONS from "../graphql/OPTIONS.gql";
import TEST from "../graphql/TEST.gql";

export default {
  data: () => ({
    showPassword: false,
    isPswOpen: false,
    kpsw: 0,
    valid: true,
    snackbar: false,
    snackbar_text: "",
    snackbar_timeout: 5000,
    username: "",
    password: "",
    loading: false,
    userdata: {},
    jsonData: null,
    fileExists: false,
    isEndpointAvailable: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  apollo: {
    // Query with parameters
    test: {
      query: TEST,
      // Additional options here
      fetchPolicy: "network-only",
      // pollInterval: 1000,
      result({ loading, networkStatus }) {
        if (!loading) this.isEndpointAvailable = networkStatus == 7;
      },
    },
  },
  created() {
    require("dotenv").config();
    this.$apollo.queries.test;
  },
  components: {
    pswform: () => import("@/components/PasswordForm.vue"),
  },
  computed: {
    img_source() {
      return code == 1 ? "ALFAPIPE.jpg" : "Mpact.gif";
    },
  },

  methods: {
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    async logout() {
      let ok = true;
      if (ok) {
        await this.maj(LOGOUT);
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push("/");
      }
    },
    async requette(query, v) {
      let r;

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async submit() {
      let ok = false;
      if (this.$refs.form.validate()) {
        if (this.isEndpointAvailable) {
          this.loading = true;
          let pws = encodeURIComponent(window.btoa(this.password));
          await this.$apollo
            .mutate({
              mutation: LOGIN,
              variables: {
                input: { username: this.username, password: pws },
              },
            })
            .then((data) => {
              //this.snackbar = true;
              if (data.data.login.code == 0 || data.data.login.code == 1) {
                onLogin(
                  this.$apollo.provider.defaultClient,
                  data.data.login.access_token
                );
                ok = true;

                this.userdata = data.data.login.user;
              } else {
                if (data.data.login.code == 2)
                  this.snackbar_text = "Utilisateur Bloqué!";
                // else if (data.data.login.code == 1)
                //   this.snackbar_text = "Utilisateur Déja connecté!";
                this.snackbar = true;
              }
            })
            .catch(() => {
              this.loading = false;
              this.snackbar_text = "Username ou Mot de passe incorrect!";
              this.snackbar = true;
            });
          if (ok) {
            let r = await this.requette(OPTIONS, {
              user_id: this.userdata.id,
            });
            if (r) {
              this.userdata = r.user;
              if (this.userdata.profile_id == "1")
                this.userdata.depots = r.depots.map((elm) => elm.id);
              else {
                if (this.userdata.depots)
                  this.userdata.depots = this.userdata.depots.split(",");
              }
              this.$store.dispatch("LoadOptions", r);
            }

            this.$store.dispatch("ActualUser", this.userdata);
            if (this.userdata.mpc == "1") {
              this.isPswOpen = true;
              this.kpsw++;
            } else {
              //this.$apollo.queries.test.stopPolling();
              this.$router.push("/dashboard");
            }
          }
        } else {
          this.snackbar_text = "erreur reseau!";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
      }
    },
    async closePswForm() {
      this.isPswOpen = false;
      await this.logout();
    },
    pwd_changed() {
      this.isPswOpen = false;
      //this.$apollo.queries.test.stopPolling();
      this.$router.push("/dashboard");
    },
    clear() {
      this.password = "";
      this.username = "";
    },
  },
};
</script>
